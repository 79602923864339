import React from 'react';
import 'twin.macro';

const Work = () => (
  <div tw="font-code">
    <h1>WiP</h1>
  </div>
);

export default Work;
